import React, { useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/variables.css"
import down from "../assets/faq-down.png"
import ScrollAnimation from 'react-animate-on-scroll'

// components
import Navigation from "../components/Nav"
import Footer from "../components/Footer"

const Wrapper = styled.div`
  background: linear-gradient(180deg, #7D2047 0%, #090836 80%);
  // min-height: 100vh;
  font-family: "Biko", sans-serif;

  > div, > section {
    padding: 0 40px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1 {
    margin: 3.6em 0 0.6em;
    font-size: var(--header-2);
    color: #DFA839;
    font-family: Magnificent;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
    text-shadow: 0px 2px 0px #201A2C;

    @media screen and (max-width: 600px){
      line-height: 1.5em;
    }
  }

  .faq-category {
    font-size: 1.6em;
    width: 100%;
    max-width: 1200px;
    text-align: left;
    color: var(--yellow);
    margin: 2em 0 0.4em;
    // padding: 0 40px;
  }

  .faq-category:first-of-type {
    margin-top: 1em;
  }

  .accordion-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
  }

  .accordion{
    cursor: pointer;
    padding: 1.4em 0;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    font-size: 20px;
    font-family: "Biko", sans-serif;
    background: none;
    color: white;
  }

  .accordion div {
    display: flex;
    justify-content: space-between;
  }

  .accordion img {
    margin: 4px 0 0 8px;
    width: 14px;
    height: 14px;
  }

  .panel {
    padding: 1em 0 1.6em;
    display: none;
    overflow: hidden;
    
    p {
      font-size: 16px;
      line-height: 20px;
      color: white;
    }
  }

  hr {
    border-color: #706583;
    border-style: solid;
  }

  @media screen and (max-width: 900px){
    > div, > section, > .faq-category {
      padding: 0 24px;
    }
  }
`

const MoreHelp = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 4em 0 2em;
    max-width: 1200px;

    h3 {
      font-size: var(--header-3);
      font-family: "The Nightmare", serif;
      color: var(--yellow);
      text-shadow: 0px 2px 0px #201A2C;
    }

    p {
      margin: 8px 0 40px;
      color: var(--white);
    }

    a {
      width: fit-content;
      transition: .3s ease-in-out;
    }

    a:hover {
      transform: scale(1.1);
    }

    .CTA {
      cursor: pointer;
      width: 200px;
      height: 50px;
      border: none;
      font: inherit;
      outline: inherit;
      border-radius: 4px;
      background-color: #B13059;
      color: white;
      border: 1px solid #B13059;
    }
`

// class FAQPage extends Component{
//   componentDidMount = () => {
//     window.scrollTo(0, 0);
//     var acc = document.getElementsByClassName("accordion");
//     var i;

//     for (i = 0; i < acc.length; i++) {
//     acc[i].addEventListener("click", function() {
//         /* Toggle between adding and removing the "active" class,
//         to highlight the button that controls the panel */
//         this.classList.toggle("active");

//         /* Toggle between hiding and showing the active panel */
//         var panel = this.nextElementSibling;
//         if (panel.style.display === "block") {
//         panel.style.display = "none";
//         } else {
//         panel.style.display = "block";
//         }
//     });
//     }
//   };
//   render(){
//     return(
//       <Layout>
//             <SEO title="FAQ Page"/>
//             <Wrapper>
//                 {/* <Navigation logo={data.logo} smallLogo={data.smallLogo} /> */}
//                 <h1>Frequently Asked Questions</h1>
//                 <div className="accordion-container">
//                     <button className="accordion">
//                       <div>
//                       <span>What are the rights and responsibilities of LIONS constituent organizations?</span>
//                       <img src={down} alt="down arrow" />
//                       </div>
//                     </button>
//                     <div className="panel">
//                         <p>
//                         All constituent organizations are called to uphold and abide by the principles, values and regulations of LIONS, while actively participate in its activities and mobilizing its members on issues, projects, and other matters relating to the right and general welfare of LIONS and its constituent organizations. More details can be found in Article VI, Section 1 & 2 of the 2020 LIONS Constitution.
//                         </p>
//                     </div>
//                     <hr />
//                 </div>
//                 <div className="accordion-container">
//                     <button className="accordion">
//                       <div>
//                       <span>What are the rights and responsibilities of LIONS constituent organizations?</span>
//                       <img src={down} alt="down arrow" />
//                       </div>
//                     </button>
//                     <div className="panel">
//                         <p>
//                         All constituent organizations are called to uphold and abide by the principles, values and regulations of LIONS, while actively participate in its activities and mobilizing its members on issues, projects, and other matters relating to the right and general welfare of LIONS and its constituent organizations. More details can be found in Article VI, Section 1 & 2 of the 2020 LIONS Constitution.
//                         </p>
//                     </div>
//                     <hr />
//                 </div>
//                 <div className="accordion-container">
//                     <button className="accordion">
//                       <div>
//                       <span>What are the rights and responsibilities of LIONS constituent organizations?</span>
//                       <img src={down} alt="down arrow" />
//                       </div>
//                     </button>
//                     <div className="panel">
//                         <p>
//                         All constituent organizations are called to uphold and abide by the principles, values and regulations of LIONS, while actively participate in its activities and mobilizing its members on issues, projects, and other matters relating to the right and general welfare of LIONS and its constituent organizations. More details can be found in Article VI, Section 1 & 2 of the 2020 LIONS Constitution.
//                         </p>
//                     </div>
//                     <hr />
//                 </div>
//                 {/* <Footer data={data} margin="0" /> */}
//             </Wrapper>
//         </Layout>
//     )
//   }
// }

export default ({ data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        /* Toggle between adding and removing the "active" class,
        to highlight the button that controls the panel */
        this.classList.toggle("active");

        /* Toggle between hiding and showing the active panel */
        var panel = this.nextElementSibling;
        var arrow = this.querySelector(".arrow");
        if (panel.style.display === "block") {
        panel.style.display = "none";
        arrow.style.transform = "rotate(0deg)";
        } else {
        panel.style.display = "block";
        arrow.style.transform = "rotate(180deg)";
        }
    });
    }
  });
  return(
    <Layout>
          <SEO title="FAQ Page"/>
          <Wrapper>
              <Navigation logo={data.logo} smallLogo={data.smallLogo} />
              <div className="content-wrapper">
              {/* <ScrollAnimation animateIn="fadeUp" animateOnce="true"> */}
              <h1>Frequently Asked Questions</h1>
              {/* </ScrollAnimation> */}
              <h3 className="faq-category">Membership and Registration</h3>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>How can I register my organization under LIONS?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      You can check out bit.ly/LIONSOrgRegistration for more details.
                      </p>
                  </div>
                  <hr />
              </div>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>What are the rights and responsibilities of LIONS constituent organizations?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      All constituent organizations are called to uphold and abide by the principles, values and regulations of LIONS, while actively participate in its activities and mobilizing its members on issues, projects, and other matters relating to the right and general welfare of LIONS and its constituent organizations. More details can be found in Article VI, Section 1 & 2 of the 2020 LIONS Constitution.
                      </p>
                  </div>
                  <hr />
              </div>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>Is there a specific timeframe wherein I can apply for the registration of my organization?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      Applications for membership may be done at any point of an academic year.
                      </p>
                  </div>
                  <hr />
              </div>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>Can all organizations be registered under LIONS?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      As stated in Article V, Section 1 of the 2020 LIONS Constitution, not all organizations can be registered with LIONS. Constituent organizations registered under LIONS must be recognized by the Office of Student Activities and registered in compliance with the Anti-Hazing Law of 2018.
                      </p>
                      <br></br>
                      <p>
                      Furthermore, constituent organizations must not be among the following: officially accredited student organizations, campus political parties, athletic groups, and official student arms of Loyola Schools offices
                      </p>
                  </div>
                  <hr />
              </div>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>How long will a constituent organization remain as a constituent organization of LIONS?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      All constituent organizations are subject to an evaluation by the Council at the end of every year. This evaluation is based on the criteria determined by the Council at the start of their term in office. Based on this evaluation, a constituent organization will be granted or revoked membership.
                      </p>
                  </div>
                  <hr />
              </div>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>Can my organization, under valid circumstances, resign from LIONS?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      Yes, provided that the constituent organization that wishes to resign its membership from LIONS must present a letter to the Council containing its reasons for resignation and the signatures of all executive officers of the said organization.
                      </p>
                  </div>
                  <hr />
              </div>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>Can a previous LIONS constituent organization that has tendered their resignation re-apply for membership?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      A constituent organization that resigns from LIONS is not barred from reapplying. If the said organization wishes to reapply, it must undergo the same membership application process.
                      </p>
                  </div>
                  <hr />
              </div>
              <h3 className="faq-category">Accreditation</h3>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>What is the difference between registration and accreditation?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      Organizations are required to be registered in compliance with the Anti-Hazing Act of 2018. This is for all groups not created or organized by the Loyola Schools (meaning not created or organized by any LS offices) but has existing members who are students of the Loyola Schools or plans to recruit students of the Loyola Schools to be its members. Basically, it is just to ensure that the recruitment and activities of organizations are safe for students to participate in.
                      </p>
                      <br></br>
                      <p>
                      In order to be accredited, the organization must first be registered then successfully undergo the Student Organizations Assessment and Review (SOAR) which OSA began to implement last 2018-2019. To learn more about the process, contact the LIONS Email (admu.lions@gmail.com) to get in touch with our Organization Development team.
                      </p>
                  </div>
                  <hr />
              </div>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>What happens when a LIONS constituent organization gets accredited?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      The membership of a constituent organization will be terminated on the event of gaining official accreditation for student organizations. The said organization shall present a letter to the Council confirming its accreditation and containing the signatures of all executive officers of the said organization.
                      </p>
                  </div>
                  <hr />
              </div>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>Can a previous LIONS constituent organization that lost its accreditation status re-apply for membership?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      Yes, a previous LIONS organization that loses its accreditation status is not barred from reapplying. If the said organization wishes to reapply, it must undergo the same membership application process.
                      </p>
                  </div>
                  <hr />
              </div>
              <h3 className="faq-category">LIONS Body</h3>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>What is the difference between the LIONS Council and the LIONS Coordinating Body?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      The Council is composed of representatives of each constituent organization and sets the direction of LIONS. The Coordinating Body, through its Coordinator and its Directors and Deputy-Members, carries out the relevant tasks and activities of LIONS.
                      </p>
                  </div>
                  <hr />
              </div>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>Who are the members of the LIONS Council?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      The LIONS Council is highest decision-making and deliberating body of LIONS and is composed of the highest executive officers (i.e. Presidents or their equivalent) of each constituent organization, serving as representatives to the body and liaisons between their organization and LIONS.
                      </p>
                  </div>
                  <hr />
              </div>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>Who are the members of the LIONS Coordinating Body?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      The LIONS Coordinating Body is composed of the Coordinator, Secretary General, Directors for Communications, Director for Finance and Logistics, Director for Organization Development, Director for Community Affairs, Director for External Relations and their respective deputy-members.
                      </p>
                  </div>
                  <hr />
              </div>
              <h3 className="faq-category">Sectoral Representation</h3>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>LIONS is a sector under the Sanggunian. What does this mean for organizations?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      Introduced in the 2016 Constitution, the Sanggunian allows legislative representatives of sectors. Under Article XIV, Section 1 of the 2017 Electoral Code, a sector is defined as “an organized group of undergraduate students of the Loyola Schools who share a specific primary characteristic and who aim to represent their underrepresented interests by advocating for their common ideologies, principles and causes.”
                      </p>
                  </div>
                  <hr />
              </div><div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>What are the main benefits of being a sector under the Sanggunian?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      Representation. The LIONS Coordinator serves as the Sectoral Representative in the Central Assembly, the highest legislative body of the Sanggunian. Their responsibility is to bring up the concerns of the unaccredited organizations to the LS administration, considering their possible disadvantages in trying to implement their projects and forward their advocacies.
                      </p>
                      <br></br>
                      <p>
                      Financial Assistance. As of the 1st Semester (2019-2020) Budget, the sectors have been provided the “New Sectors Allocation Fund” under the Office of the President. Divided equally among sectors, each LIONS organization is now allowed to have important expenses reimbursed.
                      </p>
                      <br></br>
                      <p>
                      Logistical Assistance. LIONS organizations are now allowed to reserve venues in the LS before the 15-day provision with the approval of the Sanggunian President. (Although, this is only granted under certain conditions which have yet to be clarified as of writing.)
                      </p>
                  </div>
                  <hr />
              </div><div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>What is the role of the constituent organizations in sectoral representation?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      The constituent organizations take an active part in building and fortifying the representation of LIONS as a sector through their highest executive officers that represent the organization in the LIONS Council and their liaison officers for that represent the organization when it comes to the legislative affairs of LIONS as a sector with direct involvement in the writing process of LIONS bills and statements.
                      </p>
                      <br></br>
                      <p>
                      This structure ensures better representation among the organizations for LIONS affairs through information dissemination and involvement in decision making and allows delegation in the crafting of the legislative agenda.
                      </p>
                  </div>
                  <hr />
              </div>
              <h3 className="faq-category">Communications and Partnerships</h3>
              <div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>How can I get in touch with the LIONS Coordinating Body?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      You may reach us at facebook.com/admu.lions or admu.lions@gmail.com.

                      This structure ensures better representation among the organizations for LIONS affairs through information dissemination and involvement in decision making and allows delegation in the crafting of the legislative agenda.
                      </p>
                  </div>
                  <hr />
              </div><div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>Where can I raise my concerns regarding LIONS or my organization?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      For officers and members of LIONS consituent organizations, you may fill out our grievance form to raise any concerns you may have. For other members of the Loyola Schools community, you may reach us at facebook.com/admu.lions or admu.lions@gmail.com.
                      </p>
                  </div>
                  <hr />
              </div><div className="accordion-container">
                  <button className="accordion">
                    <div>
                    <span>How can I partner with a LIONS organization for my event?</span>
                    <img className="arrow" src={down} alt="down arrow" />
                    </div>
                  </button>
                  <div className="panel">
                      <p>
                      You may get in touch with the LIONS organization you are interested in partnering with through the contact details that can be found in the public directory of LIONS: bit.ly/LIONSDirectory
                      </p>
                      <br></br>
                      <p>
                      If you are looking into extending the invitation to all constituent organizations, you may email us at admu.lions@gmail.com for further dissemination.
                      </p>
                      <br></br>
                      <p>
                      You can also find the links on the Organizations page.
                      </p>
                  </div>
                  <hr />
              </div>
              <MoreHelp>
              <ScrollAnimation animateIn="fadeFromRight">
                <h3>How else can we help you?</h3>
                <p>If you have any questions that were not answered, we would love to hear from you!</p>
                <a href="mailto:admu.lions@gmail.com"><button className="CTA">Get In Touch</button></a>
                
              </ScrollAnimation>
              </MoreHelp>
              </div>
              <Footer data={data} margin="0" />
          </Wrapper>
      </Layout>
  )
}

export const query = graphql`
  query FAQImages {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 112) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    smallLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    group: file(relativePath: { eq: "group_pic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    big: file(relativePath: { eq: "bigpic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cards: allPrismicOrgCard {
      edges {
        node {
          data {
            org__name {
              text
            }
            org_description {
              text
            }
            org_logo {
              localFile {
                absolutePath
                childImageSharp {
                  fixed(quality: 100, width: 40) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
            org_tags {
              text
            }
            org_category_list {
              org_category
            }
          }
        }
      }
    }
  }
`
// export default FAQPage